<template>
  <div>
   <h1>😎 Welcome,</h1>
   <p>... to Azure AlwaysOn! This is a reference implementation of a community driven project designed to demo and document the process, requirements and design decisions to setup a highly scalable and available application in Microsoft Azure.</p>

   <a href="https://github.com/Azure/Mission-Critical"><img src="/img/logo.png" title="AlwaysOn logo" style="width: 50%" /></a>

   <p>You can find more details <a href="https://github.com/Azure/Mission-Critical-Foundational-Online">on GitHub</a>.</p>

  </div>
</template>

<script>
export default {
  async created() { },
  methods: {  },
  computed: {  },
  components: {  }
};
</script>

<style>

</style>
